.header {
  background: rgb(55, 105, 152) !important;
  background-color: rgb(55, 105, 152) !important;
  justify-content: space-between;
}

.header img {
  display: block;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  width: 172px;
}

.header h5 {
  margin-left: 24px;
  font-family: sans-serif;
  letter-spacing: 2px;
  color: #fff !important;
}

@media (max-width: 481px) {
  .header img {
    margin: auto;
  }
}
