.home button {
  background-color: rgb(55, 105, 152);
  color: #fff !important;
}

.home button.disabled {
  background-color: #cecece !important;
}

.MuiInputAdornment-positionStart {
  margin-right: 0px !important;
}

.MuiTextField-root {
  margin-left: 0px !important;
}

.MuiTypography-overline {
  color: green !important;
}

.filter__control,
.filter__menu {
  max-width: 480px;
}
.filter__control {
  height: 56px;
}
